<script setup>

</script>

<template>
  <div class="px-4 md:px-6 lg:px-8 flex align-items-center justify-content-center">
    <div class="surface-card p-5 shadow-2 border-round mt-8 mb-8 py-7 w-full lg:w-8">
      <div class="text-900 font-bold text-4xl mb-4 text-center">Domande Frequenti</div>
      <PrimeAccordion>
        <PrimeAccordionTab header="Posso provare il prodotto?">
          <p class="m-0 text-left">
            Certo, richiedi una demo personalizzata a <a class="text-primary" href="mailto:wms@unicorne.it?subject=Info%20WMS">wms@unicorne.it</a>.
          </p>
        </PrimeAccordionTab>
        <PrimeAccordionTab header="Quanto costa?">
          <p class="m-0 text-left">
            L'abbonamento permette un piano trimestrale o annuale. Se fai il preordine, avrai uno sconto sul primo anno. Puoi interrompere quando vuoi.
          </p>
        </PrimeAccordionTab>
        <PrimeAccordionTab header="Ho delle domande">
          <p class="m-0 text-left">
            Scrivici pure a <a class="text-primary" href="mailto:wms@unicorne.it?subject=Info%20WMS">wms@unicorne.it</a>.
          </p>
        </PrimeAccordionTab>
      </PrimeAccordion>
    </div>
  </div>
</template>

<style scoped>

</style>
